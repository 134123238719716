<template>
	<div class="MaterialsMainDiv">
		<el-card class="box-card">
			<el-row>
				<span class="materialTitle">Material List</span>
				<span>-All material information will be displayed here-</span>
				<p class="hr"></p>
			</el-row>
			<el-form :inline="true" size="min" >
				<el-row>
					
					<el-col :span="8">
						<el-form-item label="Category">
							<el-input placeholder="Pls input material name." v-model="mtrlName" clearable>
							</el-input>
						</el-form-item>
					</el-col>
					
					<el-col :span="8">
						<el-form-item label="Type">
							<el-select class="vd_mtrl_select2" v-model="typeValue" placeholder="Select the material type." clearable filterable>
								<el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					
					<!--          <el-col :span="8">
											<el-form-item label="No.">
												<el-input placeholder="Pls input material num." v-model="mtrlNo" clearable>
												</el-input>
											</el-form-item>
										</el-col>-->
					
					<el-col :span="8">
						<el-form-item label="Material">
							<el-select class="vd_mtrl_select2" v-model="makeValue" placeholder="Select the material composition." clearable filterable>
								<el-option v-for="(item, index) in makeList" :key="index" :label="item" :value="item">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				
				</el-row>
				
				
				<el-row>
					
					
					
					
					<el-col :span="8">
						<el-form-item label="Color">
							<el-select id="color_class_select2" v-model="colorRealValue" placeholder="Select the material color." clearable filterable>
								<el-option v-for="(item, index) in colorList" :key="index" :label="item" :value="item">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					
					<el-col :span="8">
						<el-form-item label="Pantone">
							<el-input placeholder="Pls input material pantone. " v-model="pantoneRealValue" clearable>
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="1">&nbsp;</el-col>
					
					<el-button type="primary" class="searchButton" @click="searchMtrl()" size="medium">Search</el-button>
					
					
					<el-button type="info" class="resetButton" @click="resetSearch()"  size="medium">Reset</el-button>
				
				</el-row>
			
			</el-form>
			
			<el-row  v-loading="loading">
				<el-col :span="24">
					<el-table class="mtrlsTable" :data="tableData" style="width: 100%;font-size: 16px!important;" border>
						<el-table-column label="ID" prop="mtrl_id" align="center"></el-table-column>
						<el-table-column label="Image" prop="imge_url" align="center" width="140">
							<template slot-scope="scope">
								<imgPopover v-if="scope.row.imge_url" :imgsrc="scope.row.imge_url" :imgType="0"></imgPopover>
								<div v-else style="display: flex;justify-content: center;align-items: center;">
									<el-image class="img failedImg">
										<div slot="error" class="image-slot">
											<span class="failedImgSpan">No Image</span>
										</div>
									</el-image>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="Category" prop="mtrl_spec_en" align="center">
							<template slot-scope="scope">
								<div v-if="scope.row.mtrl_spec_en" class="vd_space">{{scope.row.mtrl_spec_en}}</div>
								<div v-else class="vd_space noData">No Data</div>
							</template>
						</el-table-column>
						<el-table-column label="No." prop="mtrl_no" align="center">
						</el-table-column>
						<el-table-column label="Specification" prop="mtrl_spec" align="center">
							<template v-slot="scope">
								<span :class="[scope.row.mtrl_spec ? '':'noData']">{{ scope.row.mtrl_spec ? scope.row.mtrl_spec : "No Data"}}</span>
							</template>
						</el-table-column>
						<el-table-column label="Type" prop="mtrl_type" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.mtrl_type == 0">Fabric</span>
								<span v-else>Accessory</span>
							</template>
						</el-table-column>
						<el-table-column label="Material" prop="mtrl_comp" align="center">
							<template slot-scope="scope">
								<span v-if="!scope.row.mtrl_comp" class="noData">No Data</span>
								<span v-else>{{ scope.row.mtrl_comp }}</span>
							</template>
						</el-table-column>
						<el-table-column label="Pantone No." prop="mtrl_color" align="center">
							<template slot-scope="scope">
								<div v-if="scope.row.mtrl_color">
									{{ scope.row.mtrl_color }}
									<div class="square" :style="{ backgroundColor: scope.row.colr_html }"></div>
								</div>
								<div v-else class="noData">No Color</div>
								
							</template>
						</el-table-column>
						<el-table-column label="Color" prop="colr_class" align="center">
							<template slot-scope="scope">
								<span v-if="!scope.row.colr_class" class="noData">No Data</span>
								<span v-else>{{ scope.row.colr_class }}</span>
							</template>
						</el-table-column>
						<el-table-column label="Unit" prop="colr_class" align="center">
							<template slot-scope="scope">
								<span v-if="!scope.row.mtrl_unit " class="noData">No Data</span>
								<span v-else>{{ scope.row.mtrl_unit }}</span>
							</template>
						</el-table-column>
						<el-table-column label="Quantity" prop="colr_class" align="center">
							<template slot-scope="scope">
								<span v-if="!scope.row.mtrl_num " class="noData">No Data</span>
								<span v-else>{{ retainDecimal(scope.row.mtrl_num) }}</span>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
			
			<el-row class="pageRow">
				<pubPagination :totalPage='totalItem' @changePageSearch="changePageSearch" ref="pubPagination">
				</pubPagination>
			
			</el-row>
		</el-card>
	
	</div>
</template>

<script>

import { get } from "@/api/request";
import { dyjAPI } from "@/api/modules/dyj";
import pubPagination from "@/components/common/pubPagination";
import imgPopover from "@/components/imgPopover.vue"

export default {
	name: 'myMenu',
	components: {
		pubPagination,
		imgPopover
	},
	created() {
		this.initData()
	},
	methods: {
		retainDecimal(value){
			if(value || value === 0){
				let ind = value.toString().indexOf('.');
				if (ind < 0) {
					return value + '.0000';
				} else {
					if (value.length - 1 - ind > 4) {
						return value.slice(0, ind + 5);
					} else if (value.length - 1 - ind < 4) {
						
						let index = 4 - (value.length - 1 - ind);
						for (let i = 0; i < index; i++) {
							value = value + '0'
						}
						return value;
					} else {
						return value;
					}
				}
			}
		},
		// 初始化方法
		initData() {
			this.getAllMtrls();
			this.getCompsList();
			this.getColorList();
		},
		// 获取所有的物料信息
		getAllMtrls() {
			this.loading = true
			// 发起请求
			get(dyjAPI.getAllMtrls, {
				pageNo: this.currentPage,
				mtrlName: this.mtrlName,
				mtrlNo: this.mtrlNo,
				type: this.typeValue,
				mtrlComp: this.makeValue,
				mtrlColor: this.pantoneRealValue,
				colrClass: this.colorRealValue,
			})
					.then((res) => {
						// 请求成功时
						if (res.data.code === 0) {
							this.tableData = res.data.data.list;
							this.totalItem = res.data.data.totalItem;
							// 失败
						} else {
							let mg = res.data.msg;
							// let tp = 'error';
							this.$message({ message: mg });
						}
						this.loading=false;
					})
					.catch(res => {
						let mg = res.data.msg;
						let tp = 'error';
						this.$message({ message: mg, type: tp });
					})
		},
		// 获取下拉框里的物料成分
		getCompsList() {
			// 发起请求
			get(dyjAPI.getCompsList)
					.then((res) => {
						// 请求成功时
						if (res.data.code === 0) {
							this.makeList = res.data.data;
							// 失败
						} else {
							let mg = res.data.msg;
							let tp = 'error';
							this.$message({ message: mg, type: tp });
						}
					})
					.catch(res => {
						let mg = res.data.msg;
						let tp = 'error';
						this.$message({ message: mg, type: tp });
					})
		},
		// 获取下拉框里的颜色大类
		getColorList() {
			// 发起请求
			get(dyjAPI.getColorList)
					.then((res) => {
						// 请求成功时
						if (res.data.code === 0) {
							this.colorList = res.data.data;
							// 失败
						} else {
							let mg = res.data.msg;
							let tp = 'error';
							this.$message({ message: mg, type: tp });
						}
					})
					.catch(res => {
						let mg = res.data.msg;
						let tp = 'error';
						this.$message({ message: mg, type: tp });
					})
		},
		// 切换颜色和潘通色号时,清空input
		colorTypeChange() {
			this.colorRealValue = "";
			this.pantoneRealValue = "";
		},
		// 分页查询
		changePageSearch(val) {
			this.currentPage = val
			this.getAllMtrls()
		},
		// 搜索按钮
		searchMtrl() {
			this.$refs.pubPagination.currentPage = 1;
			this.changePageSearch(1);
		},
		// 重置按钮
		resetSearch() {
			this.currentPage = 1,
					this.mtrlName = '',
					this.mtrlNo = '',
					this.typeValue = '',
					this.makeValue = '',
					this.colorRealValue = '',
					this.$refs.pubPagination.currentPage = 1;
			this.changePageSearch(1);
		},
		
	},
	data() {
		return {
			// 获取所有物料信息的数组
			tableData: [{
			}],
			// 获取所有颜色大类
			colorList: [{}],
			// 物料类型值
			typeValue: '',
			// 物料类型list
			typeList: [{
				value: '0',
				label: 'Fabric'
			},
				{
					value: '1',
					label: 'Accessory'
				}],
			// 物料成分值
			makeValue: '',
			// 物料成分list
			makeList: [{
			}],
			// 物料名称
			mtrlName: '',
			// 物料编号
			mtrlNo: '',
			// 潘通色号和颜色大类下拉框的默认值
			colorValue: '0',
			// 颜色大类输入框里的值
			colorRealValue: '',
			// 潘通色号输入框里的值
			pantoneRealValue: '',
			// 分页数据总数
			totalItem: 0,
			// 当前页数,默认1
			currentPage: 1,
			loading: true
		}
	},
}
</script>
<style >
.MaterialsMainDiv .clearfix:before,
.MaterialsMainDiv .clearfix:after {
	display: table;
	content: "";
}

.MaterialsMainDiv .clearfix:after {
	clear: both
}

.MaterialsMainDiv .box-card {
	height: 100%;
	margin: 20px 40px 0 230px;
	/* padding-left: 50px; */
	padding-bottom: 10px;
}


/* 2排搜索条件间距 */
.MaterialsMainDiv .mtrlsListRow {
	margin-top: 20px;
}

/* 多选框前面的文字前缀 */
.MaterialsMainDiv .vd_mtrl_prefix {
	background-color: rgb(245, 247, 250);
	padding: 11px 12px 11px;
	color: grey;
	border: 1px solid rgb(220, 223, 230);
	font-size: 14px;
	display: flex;
	float: left;
}

/* 搜索按钮 */
.MaterialsMainDiv .searchButton {
	font-size: 12px;
	margin-right: 20px;
}

/* 重置按钮 */
.MaterialsMainDiv .resetButton {
	font-size: 12px;
}

/* material标题 */
.MaterialsMainDiv .materialTitle {
	font-size: x-large;
	color: rgb(110, 110, 110);
	float: left;
	font-weight: bolder;
	margin: 10px 10px 30px 10px;
}

/* material标题后的说明 */
.MaterialsMainDiv .materialTitle+span {
	color: grey;
	float: left;
	font-size: 14px;
	margin: 18px 10px 20px 10px;
}

/* 分割线 */
.MaterialsMainDiv .hr {
	border-bottom: 1px solid grey;
	margin-top: 55px;
}

/* 物料表 */
.MaterialsMainDiv .mtrlsTable {
	margin-top: 20px;
}

/* 色块显示区域 */
.MaterialsMainDiv .square {
	height: 20px;
	width: 80px;
	margin: 0 auto;
	border: 1px solid rgba(128, 128, 128, 0.2);
}

/* 切换到颜色大类时的前面select样式 */
.MaterialsMainDiv #color_class_select {
	background-color: rgb(245, 247, 250);
	color: grey;
}

/* 分页行 */
.MaterialsMainDiv .pageRow {
	margin-top: 20px;
	padding-left: 33%;
}

/* 分页数据总数 */
.MaterialsMainDiv #totalItemSpan {
	font-size: 15px;
	color: grey;
	float: left;
	margin-top: 8px;
}

/*  hover图片时放大 */
.MaterialsMainDiv .vd_popimg {
	width: 400px;
	height: 400px;
}
/* 加载失败时的占位图片 */
.MaterialsMainDiv .failedImg {
	width: 100px;
	height: 100px;
	border: 1px solid rgba(112, 112, 112, 0.1);
	border-radius: 6px;
}
.failedImg {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.failedImgSpan{
	color: #ccc;
}
/* 加载成功时的小图 */
.MaterialsMainDiv .vd_elimg {
	width: 100px;
	height: 100px;
	margin-top: 6px;
}
.MaterialsMainDiv .el-form--inline .el-form-item__label {
	width: 130px;
	text-align: right;
}
.vd_space {
	word-wrap: break-word;
	word-break: normal;
	font-size: 16px !important;
}
</style>